<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ChevronLeft } from 'lucide-vue-next'
import * as z from 'zod'

import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

import {Button} from '@/components/ui/button'
import { FormFeature } from '@/components/forms'

const store = useStore()
const router = useRouter()

const feature = computed(() => store.getters['geo/getCurrentFeature'])
const isMapReady = computed(() => store.getters['geo/getIsMapReady'])
const geometryErrors = ref(null)
const draw = computed(() => store.getters['geo/getDraw'])

const schema = z.object({
  latitude: z
    .number({ invalid_type_error: "Latitude must be a number." })
    .min(-90, "Latitude must be greater than or equal to -90.")
    .max(90, "Latitude must be less than or equal to 90."),
  longitude: z
    .number({ invalid_type_error: "Longitude must be a number." })
    .min(-180, "Longitude must be greater than or equal to -180.")
    .max(180, "Longitude must be less than or equal to 180."),
})

const back = () => {
  router.push({name: 'Feature', params: {featureCode: feature.value.properties.code}})
}

const validateSchema = () => {
  draw.value.set({
    type: 'FeatureCollection',
    features: [feature.value]
  })
  try {
    schema.parse({
      latitude: props.feature.geometry.coordinates[1],
      longitude: props.feature.geometry.coordinates[0]
    })
    geometryErrors.value = null
  } catch (error) {
    geometryErrors.value = error.errors
  }
}
</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between">
      <Button size="sm" variant="ghost" class="w-8 h-8 rounded-full p-0" @click="back">
        <ChevronLeft class="text-gray-500" />
      </Button>
    </div>
    <div v-if="feature && isMapReady" class="mt-2">
      <p class="mb-3 text-gray-600">Click on the feature to edit it.</p>

      <div class="mt-3 mb-3" v-if="feature.geometry.type === 'Point'">
        <div class="flex items-center space-x-2">
          <div>
            <Label>Longitude</Label>
            <Input v-model="feature.geometry.coordinates[0]" @input="validateSchema" type="number" step="0.0000001" />
          </div>
          <div>
            <Label>Latitude</Label>
            <Input v-model="feature.geometry.coordinates[1]" @input="validateSchema" type="number" step="0.0000001" />
          </div>
        </div>
        <div v-if="geometryErrors" class="text-red-500 text-xs">
          <ul>
            <li v-for="error in geometryErrors">{{ error.message }}</li>
          </ul>
        </div>
      </div>

      <FormFeature
        v-if="feature.geometry"
        :editMode="true"
        :feature="feature"
        :key="feature"
        @update:saved="back"
      />
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>
