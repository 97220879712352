<script setup lang="ts">
import { computed } from 'vue'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useEmitAsProps,
} from 'radix-vue'
import { X } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const props = defineProps<DialogContentProps & {
  class?: string,
  noOverlay?: boolean,
  persistent?: boolean,
  to?: string | 'html'
}>()

const emits = defineEmits<DialogContentEmits>()

const emitsAsProps = useEmitAsProps(emits)
</script>

<template>
  <DialogPortal :to="props.to">
    <DialogOverlay
      v-if="!props.noOverlay"
      class="fixed inset-0 z-40 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />

    <DialogContent
      :class="
        cn(
          {
            'absolute top-0 left-0': !!props.to,
            'fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]': !!!props.to,
          },
          'z-40 grid w-full max-w-lg gap-4 border border-border bg-background p-3 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full',
          props.class,
        )
      "
      v-bind="{ ...props, ...emitsAsProps }"
      @interact-outside="event => {
        event.preventDefault()
      }"
    >
      <slot />
      <DialogClose
        v-if="!props.persistent"
        class="absolute top-3 right-3 p-0.5 transition-colors rounded-md hover:bg-secondary"
      >
        <X class="w-4 h-4" />
        <span class="sr-only">Close</span>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
