<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'

import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Badge } from '@/components/ui/badge'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { DatePicker } from 'v-calendar'
import { formatTime } from '@/utils/datetime'
import { Button } from '@/components/ui/button'

const availableRange = [
  new Date(Date.UTC(2016, 1, 1, 0, 0, 0)),
  new Date(Date.UTC(2020, 2, 1, 0, 0, 0)),
]

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const selectedRange = ref(availableRange)
const formSchema = toTypedSchema(z.object({
  start_date: z.date().default(selectedRange.value[0]).refine(value => {
    return value >= selectedRange.value[0] && value <= selectedRange.value[1]
  }, 'Date must be within the available range'),
  end_date: z.date().default(selectedRange.value[1]).refine(value => {
    return value >= selectedRange.value[0] && value <= selectedRange.value[1]
  }, 'Date must be within the available range')
}))

const onSubmit = () => {
  console.log('Selected Range:', {
    start_date: selectedRange.value[0],
    end_date: selectedRange.value[1],
  })
}
</script>

<template>
  <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
    <div>
      <div class="mb-3">
        <h3>Available range</h3>
        <p>
          {{ formatTime(availableRange[0], 'UTC', 'D MMMM YYYY') }} -
          {{ formatTime(availableRange[1], 'UTC', 'D MMMM YYYY') }}
        </p>
      </div>

      <div class="mb-3">
        <FormField v-slot="{ componentField }" name="start_date">
          <FormItem>
            <FormLabel>Start date</FormLabel>
            <Popover>
              <PopoverTrigger>
                <Badge variant="secondary">
                  {{ formatTime(selectedRange[0], settings.tz, 'D MMMM YYYY') }}
                </Badge>
              </PopoverTrigger>
              <PopoverContent class="p-0 w-full">
                <DatePicker v-bind="componentField" :min-date="selectedRange[0]" :max-date="selectedRange[1]" />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>

      <div class="mb-3">
        <FormField v-slot="{ componentField }" name="end_date" >
          <FormItem>
            <FormLabel>End date</FormLabel>
            <Popover>
              <PopoverTrigger>
                <Badge variant="secondary">
                  {{ formatTime(selectedRange[1], settings.tz, 'D MMMM YYYY') }}
                </Badge>
              </PopoverTrigger>
              <PopoverContent class="p-0 w-full">
                <DatePicker v-bind="componentField" :min-date="selectedRange[0]" :max-date="selectedRange[1]" />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
    </div>

    <div class="mt-4 flex justify-end">
      <Button type="submit">Add</Button>
    </div>
  </Form>
</template>
