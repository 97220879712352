<script setup>
import { ref, computed } from 'vue'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { FormCreatePlanetScopeLayer, FormCreatePlanetVarLayer } from '@/components/layers'
import { Label } from '@/components/ui/label'

const sources = [
  {
    label: 'Planet Labs',
    value: 'planet',
    products: [
      {
        label: 'Planetary Variables',
        value: 'planetVars',
        isFeature: true,
        component: FormCreatePlanetVarLayer
      },
      {
        label: 'PlanetScope',
        value: 'planetScope',
        isFeature: false,
        component: null,
        component: FormCreatePlanetScopeLayer
      },
      { label: 'SkySat', value: 'skysat', isFeature: true, component: null }
    ]
  },
  {
    label: 'Sentinel',
    value: 'sentinel',
    products: [],
    component: null
  },
]

const selectedSource = ref(sources[0].value)
const selectedProductValue = ref(sources[0].products[0]?.value)
const selectedProduct = computed(() => {
  return sources.find(source => source.value === selectedSource.value)?.products.find(product => product.value === selectedProductValue.value)
})
</script>

<template>
  <div class="p-2">
    <Tabs :defaultValue="sources[0].value" class="w-full">
      <div>
        <TabsList class="grid w-full grid-cols-2">
          <TabsTrigger
            v-for="source in sources"
            :key="source.value"
            :value="source.value"
          >
            <div class="flex items-center">
              <span>{{ source.label }}</span>
            </div>
          </TabsTrigger>
        </TabsList>
      </div>
      <TabsContent
        v-for="source in sources"
        :key="source.value"
        :value="source.value"
      >
        <div v-if="source.products?.length > 0">
          <Label class="mb-1">Product</Label>
          <Select v-model="selectedProductValue">
            <SelectTrigger>
              <SelectValue placeholder="Select product" />
            </SelectTrigger>
            <SelectContent class="max-h-[300px]">
              <SelectGroup>
                <SelectItem
                  v-for="product in source.products"
                  :key="product.value"
                  :value="product.value"
                >
                  {{ product.label }}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          <hr class="mt-4" />
          <div class="mt-3" :key="selectedProduct.value">
            <component
              :is="selectedProduct.component"
              v-if="selectedProduct.component"
              :feature="{}"
            />
            <div v-else>
              <div class="py-4 text-center font-bold">Coming Soon</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="py-4 text-center font-bold">Coming Soon</div>
        </div>
      </TabsContent>
    </Tabs>
  </div>
</template>
