<script setup>
import { ref, computed, onBeforeUnmount, watch } from 'vue'
import {debounce} from 'lodash'
import { useStore } from 'vuex'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Spinner } from '@/components/ui/spinner'
import {Trash2, X, Eye, FileArchive} from 'lucide-vue-next'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { PlusCircle } from 'lucide-vue-next'
import axios from 'axios'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { FormCreatePlanetScopeLayer, FormCreatePlanetVarLayer } from '@/components/layers'
import { Label } from '@/components/ui/label'
import {parseKML, parseKMZ, parseGeoJSON} from '@/composables/files'

const props = defineProps({
  feature: {
    type: Object,
    required: true
  },
  org: {
    type: Object,
    required: true
  },
})

const store = useStore()

const sources = [
  {
    label: 'Planet Labs',
    value: 'planet',
    products: [
      {
        label: 'Planetary Variables',
        value: 'planetVars',
        isFeature: true,
        component: FormCreatePlanetVarLayer
      },
      {
        label: 'PlanetScope',
        value: 'planetScope',
        isFeature: false,
        component: null,
        component: FormCreatePlanetScopeLayer
      },
      { label: 'SkySat', value: 'skysat', isFeature: true, component: null }
    ]
  },
  {
    label: 'Sentinel',
    value: 'sentinel',
    products: [],
    component: null
  },
]

const selectedOrganizationId = ref(props.org.organization_id.toString())
const selectedSource = ref(sources[0].value)
const selectedProductValue = ref(sources[0].products[0]?.value)
const selectedProduct = computed(() => {
  return sources.find(source => source.value === selectedSource.value)?.products.find(product => product.value === selectedProductValue.value)
})

const previewGeometry = ref(null)
const selectedGeometry = ref(null)
const isSearching = ref(false)
const searchText = ref('')
const searchFeatures = ref([])
const featureFileInput = ref(null)
const isOpen = ref(false)

const resetSearch = () => {
  searchFeatures.value = []
  selectedGeometry.value = null
  previewGeometry.value = null
}

const searchLocation = async (value) => {
  resetSearch()
  try {
    const response = await axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: value,
        polygon_geojson: 1,
        format: 'json',
        limit: 6
      }
    })

    const results = response.data

    if (results.length === 0) {
      return
    }

    for (const result of results) {
      if (result.geojson.type === 'Point' || result.geojson.type === 'LineString') {
        continue
      }
      const feature = {
        type: 'Feature',
        geometry: result.geojson,
        properties: {
          isSearch: true,
          name: result.display_name || result.name,
          addresstype: result.addresstype,
          placeId: result.place_id,
        }
      }

      searchFeatures.value.push(feature)
    }
  } catch (error) {
    console.error(error)
  }
}

const showFeature = (feature) => {
  previewGeometry.value = feature
}

const search = debounce(async () => {
  isSearching.value = true
  await searchLocation(searchText.value)
  isSearching.value = false
}, 1000)

const handleFileInputClick = () => {
  featureFileInput.value[0].click()
}

const handleFile = async (e) => {
  const files = e.target.files
  if (!files) return
  if (files.length === 0) return

  const file = files[0]
  const ext = file.name.split('.').pop().toLowerCase()
  let collection, name

  switch (ext) {
    case 'kml':
      ({ collection, name } = await parseKML(file))
      break
    case 'kmz':
      ({ collection, name } = await parseKMZ(file))
      break
    case 'geojson':
      ({ collection, name } = await parseGeoJSON(file))
      break
    default:
      toast({ title: 'Invalid file type', status: 'error' })
      break
  }

  if (!collection) {
    return
  }

  const features = collection.features
  if (features.length === 0) {
    toast({ title: 'No features found in file', status: 'error' })
    return
  }

  for (const feature of features) {
    feature.properties.isFromFile = true
    searchFeatures.value.push(feature)
  }
  selectedGeometry.value = features[0]
}

const resetFiles = () => {
  featureFileInput.value.value = ''
}

const selectGeometry = async (feature) => {
  if (!feature) {
    selectedGeometry.value = null
    return
  }
  selectedGeometry.value = feature
}

onBeforeUnmount(() => {
  resetSearch()
})

watch(() => searchText.value, async (value) => {
  if (searchText.value.length === 0) {
    resetSearch()
    return
  }

  await search()
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button class="px-2" variant="ghost">
          <div class="flex items-center justify-between">
            <PlusCircle class="text-gray-500 w-4 h-4 mr-1" />
            Add Layer
          </div>
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px] m-3" no-overlay to="#mapSection">
      <DialogHeader>
        <DialogTitle>New layer</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <Tabs :defaultValue="sources[0].value" class="w-full max-h-[75vh] overflow-x-auto">
        <div>
          <TabsList class="grid w-full grid-cols-2">
            <TabsTrigger
              v-for="source in sources"
              :key="source.value"
              :value="source.value"
            >
              <div class="flex items-center">
                <span>{{ source.label }}</span>
              </div>
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent
          v-for="source in sources"
          :key="source.value"
          :value="source.value"
        >
          <div class="mb-3">
            <Label class="mb-1">Organization</Label>
            <p>{{ org.organization_name }}</p>
            <!-- <Select v-model="selectedOrganizationId">
              <SelectTrigger>
                <SelectValue placeholder="Select org" />
              </SelectTrigger>
              <SelectContent class="max-h-[300px]">
                <SelectGroup>
                  <SelectItem
                    v-for="org in orgs"
                    :key="org.organization_id"
                    :value="org.organization_id.toString()"
                  >
                    {{ org.organization_name }}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select> -->
          </div>

          <div v-if="source.products?.length > 0">
            <Label class="mb-1">Product</Label>
            <Select v-model="selectedProductValue">
              <SelectTrigger>
                <SelectValue placeholder="Select product" />
              </SelectTrigger>
              <SelectContent class="max-h-[300px]">
                <SelectGroup>
                  <SelectItem
                    v-for="product in source.products"
                    :key="product.value"
                    :value="product.value"
                  >
                    {{ product.label }}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>

            <div v-if="selectedProduct.isFeature" class="mt-3">
              <Label class="mb-1">Geometry Required</Label>
              <div class="border border-gray-200 rounded p-2">
                <div class="relative">
                  <Input
                    placeholder="Search for country, city or place"
                    v-model="searchText"
                  />
                  <Button
                    v-if="searchText.length > 0 && !isSearching"
                    variant="ghost"
                    class="p-0 w-6 h-6 absolute top-2 right-2"
                    @click="searchText = ''"
                  >
                    <X class="w-4 h-4 text-gray-500" />
                  </Button>
                  <Spinner v-if="isSearching" class="w-4 h-4 absolute top-3 right-3" />
                </div>

                <div class="flex items-center">
                  <div class="flex-1 border-t border-gray-200"></div>
                  <div class="mx-2 text-gray-400">or</div>
                  <div class="flex-1 border-t border-gray-200"></div>
                </div>

                <div class="flex justify-center">
                  <Button  @click="handleFileInputClick" size="sm" variant="secondary" type="button">
                    <FileArchive class="w-4 h-4 mr-2" />
                    <span>Add shapefile</span>
                  </Button>
                  <input
                    ref="featureFileInput"
                    type="file"
                    accept=".shp, .kml, .kmz, .geojson"
                    @change="handleFile($event)"
                    @click="resetFiles()"
                    style="visibility: hidden; position: absolute; top: 0; left: 0; pointer-events: none;"
                  />
                </div>

                <FormField v-slot="{ value, componentField }" name="placeDetails">
                  <FormItem>
                    <FormControl>
                      <div v-if="searchFeatures.length > 0" class="my-2">
                        <h3>Select one of:</h3>
                        <div class="bg-white border border-gray-200 rounded-md overflow-hidden">
                          <div
                            v-for="(feature, index) in searchFeatures"
                            :key="feature.id"
                            class=" border-gray-200 border-b last:border-b-0 cursor-pointer"
                            :class="{'bg-secondary': selectedGeometry === feature}"
                          >
                            <div class="flex justify-between">
                              <div @click="selectGeometry(feature, setValues)" class="w-full px-2 py-1">
                                <div class="flex items-center justify-between">
                                  <h4 class="text-sm text-gray-600 mb-0.5" :class="{'font-semibold text-primary' : selectedGeometry === feature}">
                                    <span v-if="!feature.properties.name">Drawed ({{ index + 1 }})</span>
                                    <span v-else>
                                      {{ feature.properties.name }}
                                    </span>
                                  </h4>
                                </div>
                                <ul class=" text-gray-400 text-xs">
                                  <li v-if="feature.properties.addresstype" class="capitalize">Type: {{ feature.properties.addresstype }}</li>
                                  <li>Geometry: {{ feature.geometry.type }}</li>
                                  <li v-if="feature.properties.areaText">Size: {{ feature.properties.areaText }}</li>
                                  <!-- <li>{{ feature.properties.placeId }}</li> -->
                                </ul>
                              </div>
                              <div class="flex p-1">
                                <Button size="sm" variant="ghost" class="w-6 h-6 p-1" @click="showFeature(feature)" type="button">
                                  <Eye class="text-gray-600" />
                                </Button>
                                <!-- <Button v-if="!feature.properties.isSearch" @click="deleteFeature(feature.properties.code)" size="sm" variant="ghost" class="w-6 h-6 p-1" type="button">
                                  <Trash2 class="text-red-400" />
                                </Button> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </FormField>
              </div>
            </div>

            <hr class="mt-4" />
            <div
              class="mt-3"
              :class="{'pointer-events-none opacity-50': !selectedOrganizationId || (!selectedGeometry && selectedProduct.isFeature)}"
              :key="selectedProduct.value"
            >
              <component
                :is="selectedProduct.component"
                v-if="selectedProduct.component"
                :feature="selectedGeometry ? selectedGeometry : {}"
                :orgId="selectedOrganizationId"
                @formSubmitted="isOpen = false"
              />
              <div v-else>
                <div class="py-4 text-center font-bold">Coming Soon</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="py-4 text-center font-bold">Coming Soon</div>
          </div>
        </TabsContent>
      </Tabs>
    </DialogContent>
  </Dialog>
</template>
