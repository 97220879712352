<script setup>
import {computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import { MapSource, MapLayer } from '@/components/map'

const protocol = location.protocol === 'https:' ? 'https' : 'http'
const host = `${protocol}://${window.location.host}`

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const layersToken = computed(() => store.getters['geo/getTilesToken'])

const props = defineProps({
  slot: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    required: false,
    default: () => ({})
  }
})

onMounted(async () => {
  await store.dispatch('geo/fetchLayersToken')
})
</script>

<template>
  <div v-if="map && layersToken">
    <MapSource
      :map="map"
      type="raster"
      id="test-tiff-tiles-source"
      :options="{
        tiles: [`${host}/tulum/tiles/test-tiff/{z}/{x}/{y}.png?token=${layersToken}`],
        tileSize: 256,
      }"
    >
      <MapLayer
        :map="map"
        id="test-tiff-tiles-layer"
        type="raster"
        source="test-tiff-tiles-source"
        :slot="slot"
        :before-id="'default-layer'"
        :options="options"
      ></MapLayer>
    </MapSource>
  </div>
</template>
