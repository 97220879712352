<script setup lang="ts">
import { ref, shallowRef, watchEffect } from 'vue'
import { TreeRoot } from 'radix-vue'

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { type Instruction, extractInstruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item'
import { updateTree } from './utils.ts'

import { LayerItem } from '@/components/layers'

const props = defineProps<{
  layers: any[]
}>()

const items = shallowRef(props.layers)
const renderFlags = ref<boolean[]>([])

// temperal solution to render items with delay
// mapbox slots renders correctly but for some reason big raster inside slots are not rendered correctly
// also we need to avoid re-rendering the whole tree, but have no time to implement it now
watchEffect(() => {
  renderFlags.value = Array(items.value.length).fill(false)

  items.value.forEach((_, index) => {
    setTimeout(() => {
      renderFlags.value[index] = true
    }, index * 1000)
  })
})

watchEffect((onCleanup) => {
  const dndFunction = combine(
    monitorForElements({
      onDrop(args) {
        const { location, source } = args
        // didn't drop on anything
        if (!location.current.dropTargets.length) return

        const itemId = source.data.id as string
        const target = location.current.dropTargets[0]
        const targetId = target.data.id as string

        const instruction: Instruction | null = extractInstruction(
          target.data,
        )

        if (instruction !== null) {
          items.value = updateTree(items.value, {
            type: 'instruction',
            instruction,
            itemId,
            targetId,
          }) ?? []
        }
      },
    }),
  )

  onCleanup(() => {
    dndFunction()
  })
})
</script>

<template>
  <div class="w-full">
    <TreeRoot
      v-slot="{ flattenItems }"
      class="list-none select-none w-full bg-white text-blackA11 rounded-md p-2 text-sm font-medium"
      :items="items"
      :get-key="(item) => item.uuid"
      multiple
      propagate-select
    >
      <LayerItem
        v-for="(item, idx) in flattenItems"
        :key="item._id"
        :item="item"
        :renderFlags="renderFlags"
        v-bind="item.bind"
        :style="{ 'padding-left': `${item.level - 1}rem` }"
        class="rounded outline-none focus:ring-grass9 focus:ring-2 data-[selected]:bg-grass4"
        @select.prevent
      />
    </TreeRoot>
  </div>
</template>
