export const mutations = {
  SET_LAYERS(state, payload) {
    state.layers = state.layers.concat(payload)
  },
  UPDATE_LAYER(state, payload) {
    state.layers = state.layers.map(layer => {
      if (layer.uuid === payload.uuid) {
        return payload
      }
      return layer
    })
  }
}
