<script setup>
import {computed, ref, onMounted, onBeforeUnmount, watch} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import {Spinner} from '@/components/ui/spinner'
import { v4 as uuidv4 } from 'uuid'
import { DatePicker } from 'v-calendar'

import {ImagesList} from '@/components/ui/images'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {Badge} from '@/components/ui/badge'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import Label from "@/components/ui/label/Label.vue";
import { Textarea } from '@/components/ui/textarea'

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"]
const MAX_FILE_SIZE = 1000 * 1000 * 5
const MAX_IMAGES_COUNT = 4

const formUUID = uuidv4()
const previews = ref({
  fullsizes: [],
  thumbnails: [],
})

const props = defineProps({
  feature: {
    type: Object,
    required: true,
  },
  templateId: {
    type: String,
    default: null,
  },
  predefinedProjectId: {
    type: String,
    default: null,
  },
  editMode: {
    type: Boolean,
    default: false,
  },
  predefined: {
    type: Boolean,
    default: false,
  },
  hideClose: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const router = useRouter()

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const isMapReady = computed(() => store.getters['geo/getIsMapReady'])
const member = computed(() => store.getters['user/getMember'])
const lastSelectedProject = computed(() => store.getters['project/getLastSelected'])
const projects = computed(() => store.getters['project/getFlatedProjects'])
const templates = computed(() => {
  const templates = store.getters['geo/getProjectReportTemplates']
  if (!templates) return []
  return templates.filter(t => t.geometry_type === geojson.value.geometry.type)
})
const draw = computed(() => store.getters['geo/getDraw'])

const projectId = computed(() => route.params.project_id || props.predefinedProjectId || lastSelectedProject.value?.project_id.toString())
const isSubmiting = ref(false)
const geojson = ref(null)
const selectedProjectId = ref(null)

geojson.value = props.feature

const isAllowChangeCreatedDate = computed(() => geojson.value.properties.created_origin)

const emit = defineEmits(['update:created', 'update:close', 'update:saved', 'update:feature'])

const schema = ref(z.object({
  color: z.string().default(props.feature && geojson.value.properties?.color ? geojson.value.properties.color : '#0059ff'),
  project_id: z.string().default(projectId.value ? projectId.value : null),
  template_id: z.string().nullable().optional().default(props.templateId ? props.templateId : null),
  name: z.string().min(2).max(255).default(props.feature ? geojson.value.properties.name : ''),
  description: z.string().max(255).optional().default(props.feature ? geojson.value.properties.description : ''),
  shared: z.boolean().default(false).default(props.feature ? geojson.value.properties.shared : false),
  geofence: z.boolean().default(false),
  geofenceEvents: z.string().default(null).optional(),
  geofencePrompt: z.boolean().default(false),
  created_origin: z.date().default(geojson.value.properties.created_origin ? new Date(geojson.value.properties.created_origin) : new Date(Date.now())),
  images:
    z.any()
    .nullable()
    .optional()
    .default(props.feature && geojson.value.properties.resource ? [geojson.value.properties.resource] : null)
    // .refine((files) => files?.[0]?.size <= MAX_FILE_SIZE, `Max file size is 5MB.`)
    // .refine(
    //   (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
    //   `${ACCEPTED_IMAGE_TYPES} files are accepted.`
    // )
}))

const onSubmit = async (values) => {
  isSubmiting.value = true

  if (!props.editMode) {
    const feature = await store.dispatch('geo/saveFeature', {
      project_id: values.project_id,
      files: values.images,
      feature: {
        type: 'Feature',
        properties: {
          name: values.name,
          description: values.description,
          shared: values.shared,
          member_id: member.value.member_id,
          created_origin: values.created_origin.getTime(),
          type: geojson.value.geometry.type,
          subtype: 'report', // deprecated, need to remove
          geofence: values.geofence,
          geofence_events: values.geofenceEvents === 'both' ? ['in', 'out'] : [values.geofenceEvents],
          geofence_prompt: values.geofencePrompt,
          template_id: parseInt(values.template_id),
          color: values.color,
          project_id: values.project_id,

          data: values.dataFields ? Object.entries(values.dataFields).map(([key, value]) => {
            return {
              key,
              value,
              type: typeof value === 'number' ? 1 : typeof value === 'boolean' ? 2 : 0
            }
          }) : []
        },
        geometry: geojson.value.geometry
      }
    })
    geojson.value.properties.code = feature.properties.code
    geojson.value.properties.project_id = feature.properties.project_id
    emit('update:created', geojson.value)
    router.push({ name: 'Feature', params: { featureCode: feature.properties.code, project_id: feature.properties.project_id }})
  } else {
    const feature = await store.dispatch('geo/updateFeature', {
      project_id: values.project_id,
      files: values.images,
      feature: {
        type: 'Feature',
        properties: {
          ...geojson.value.properties,
          name: values.name,
          description: values.description,
          shared: values.shared,
          member_id: member.value.member_id,
          created_origin: values.created_origin.getTime(),
          type: geojson.value.geometry.type,
          subtype: 'report', // deprecated, need to remove
          geofence: values.geofence,
          geofence_events: values.geofenceEvents === 'both' ? ['in', 'out'] : [values.geofenceEvents],
          geofence_prompt: values.geofencePrompt,
          template_id: parseInt(values.template_id),
          color: values.color,
          project_id: values.project_id,

          data: values.dataFields ? Object.entries(values.dataFields).map(([key, value]) => {
            return {
              key,
              value,
              type: typeof value === 'number' ? 1 : typeof value === 'boolean' ? 2 : 0
            }
          }) : []
        },
        geometry: geojson.value.geometry
      },
    })

    emit('update:saved', geojson.value)
    router.push({ name: 'Feature', params: { featureCode: feature.properties.code, project_id: feature.properties.project_id }})
  }


  toast({
    title: `Geometry ${values.name} saved`,
  })

  isSubmiting.value = false
}

const handleProjectChange = (id) => {
  selectedProjectId.value = id
  schema.value = schema.value.extend({
    project_id: z.string().default(id),
  })
  store.dispatch('geo/fetchProjectReportTemplates', id)
}

const selectedTemplateId = ref(null)

const handleTemplateSelect = (id, projectId) => {
  const template = templates.value.find(t => t.id === parseInt(id))
  if (!template?.data_fields) return
  const dataFields = template.data_fields.reduce((acc, field) => {
    switch (field.type) {
      case 0:
        acc[field.key] = z.string().describe({ type: field.type})
        break
      case 1:
        acc[field.key] = z.number().describe({ type: field.type})
        break
      case 2:
        acc[field.key] = z.boolean().default(false).describe({ type: field.type})
        break
      case 3:
        acc[field.key] = z.string().describe({ type: field.type})
        break
      case 4:
        acc[field.key] = z.enum(field.options).describe({ type: field.type})
        break
    }
    // acc[field.key] = templateTypeToSchema[field.type]
    return acc
  }, {})

  schema.value = schema.value.extend({
    dataFields: z.object(dataFields),
    name: z.string().min(3).max(255).default(template.name),
    template_id: z.string().optional().default(id),
    project_id: z.string().default(projectId),
    color: z.string().default(template.color),
    shared: z.boolean().default(template.shared),
  })

  selectedTemplateId.value = id
}

const close = () => {
  emit('update:close')
}

const setImagePreview = (file) => {
  const blobUrl = URL.createObjectURL(file)
  previews.value.fullsizes.push({type: 'fullsize', url: blobUrl})
  previews.value.thumbnails.push({type: 'thumbnail', url: blobUrl})
}

const setImagesPreview = (event) => {
  previews.value = {
    fullsizes: [],
    thumbnails: [],
  }
  for (let i = 0; i < event.target.files.length; i++) {
    setImagePreview(event.target.files[i])
  }
}

const changeFeature = (values) => {
  geojson.value = {
    ...geojson.value,
    properties: {
      ...geojson.value.properties,
      ...values,
    }
  }
  emit('update:feature', geojson.value)
}

const submitButton = ref(null)
watch(() => props.feature?.properties?.isUpload, (value) => {
  if (value) {
    submitButton.value.click()
  }
})

watch(projectId, (id) => {
  if (id) {
    handleProjectChange(id)
  }
})

onMounted(() => {
  map.value.on('draw.update', () => {
    const data = draw.value.getAll()
    const feature = data.features.find(f => f.properties.code === props.feature.properties.code)
    if (feature) {
      geojson.value.geometry = feature.geometry
      emit('update:feature', geojson.value)
    }
  })

  if (projectId.value) {
    handleProjectChange(projectId.value)

    if (props.templateId) {
      handleTemplateSelect(props.templateId, projectId.value)
    }
  }

  if (props.feature.properties.resource) {
    setImagePreview(props.feature.properties.resource)
  }
})

onBeforeUnmount(() => {
  store.commit('geo/SET_REPORT_TEMPLATES', null)
  previews.value = {
    fullsizes: [],
    thumbnails: [],
  }
})
</script>

<template>
<Form
  v-slot="{ getValues }"
  class="space-y-3"
  @submit="onSubmit"
  :validation-schema="toTypedSchema(schema)"
  :key="selectedTemplateId || projectId || selectedProjectId"
>
  <FormField v-slot="{ value, componentField }" name="project_id">
    <FormItem>
      <FormLabel>Project</FormLabel>
      <FormControl>
        <Select v-bind="componentField" @update:model-value="() => handleProjectChange(getValues().project_id)">
          <SelectTrigger>
            <SelectValue placeholder="Select a project" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="(p, index) of projects"
                :key="index"
                :value="`${p.project_id}`"
              >
                {{p.project_name}}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-slot="{ componentField }" name="created_origin">
    <FormItem>
      <FormLabel>Created</FormLabel>
      <FormControl>
        <div :class="{'pointer-events-none opacity-25': !isAllowChangeCreatedDate}">
          <Popover>
            <PopoverTrigger>
              <Badge variant="secondary">
                {{ new Date(componentField.modelValue).toLocaleString() }}
              </Badge>
            </PopoverTrigger>
            <PopoverContent class="p-0 w-full">
              <DatePicker v-bind="componentField" mode="dateTime" is24hr />
            </PopoverContent>
          </Popover>
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-if="!templateId && selectedProjectId && templates?.length" v-slot="{ componentField }" name="template_id">
    <FormItem>
      <FormLabel>Template</FormLabel>
      <FormControl>
        <Select v-bind="componentField" @update:model-value="handleTemplateSelect(getValues().template_id, getValues().project_id)">
          <SelectTrigger>
            <SelectValue placeholder="Select a type" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="(t, index) of templates"
                :key="index"
                :value="t.id.toString()"
              >
                {{t.name}}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-else-if="templates?.length" v-slot="{ value, componentField }" name="template_id">
    <FormItem>
      <FormLabel>Template</FormLabel>
      <FormControl>
        <span class="text-gray-500">
          {{ templates?.find(t => t.id === parseInt(templateId))?.name }}
        </span>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <div class="flex space-x-3">
    <div class="basis-5/6">
      <FormField v-slot="{ componentField }" name="name">
        <FormItem>
          <FormLabel>Name</FormLabel>
          <FormControl>
            <Input v-bind="componentField" :disabled="selectedTemplateId ? true : false" />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
    <div class="basis-1/6">
      <FormField v-slot="{ componentField }" name="color">
        <FormItem>
          <FormLabel>Color</FormLabel>
          <FormControl>
            <div class="pt-1">
              <label :for="`colorPicker-${formUUID}`" :style="`background-color: ${componentField.modelValue}`" class="flex w-8 h-8 rounded-full">
                <input @change="changeFeature(getValues())" :id="`colorPicker-${formUUID}`" class="mr-1 w-full h-full invisible" type="color" :value="componentField.modelValue" v-bind="componentField" :disabled="selectedTemplateId ? true : false" />
              </label>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
  </div>

  <FormField v-slot="{ componentField }" name="description">
    <FormItem>
      <FormLabel>Description</FormLabel>
      <FormControl>
        <Input v-bind="componentField" />
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <div v-if="selectedProjectId && templates?.length && schema.shape?.dataFields" class="space-y-3 pb-4">
    <h4 class="font-bold">Collected data</h4>

    <FormField
      v-for="(key, index) in Object.entries(schema.shape.dataFields.shape)"
      v-slot="{ componentField, value, handleChange }"
      :key="index" :name="'dataFields.' + key[0]"
    >
      <FormItem>
        <FormLabel v-if="key[1].description.type !== 2">{{ key[0] }}</FormLabel>
        <FormControl>
          <Input v-if="key[1].description.type === 0" v-bind="componentField" type="text" />
          <Input v-if="key[1].description.type === 1" v-bind="componentField" type="number" />
          <div v-if="key[1].description.type === 2" class="flex items-center">
            <Switch
              :checked="value"
              @update:checked="handleChange"
            />
            <Label class="ml-2">{{ key[0] }}</Label>
          </div>
          <Textarea v-if="key[1].description.type === 3" v-bind="componentField" />
          <RadioGroup v-if="key[1].description.type === 4" v-bind="componentField">
            <FormItem v-for="value in key[1]._def.values" class="flex items-center space-y-0 gap-x-3">
              <FormControl>
                <RadioGroupItem :value="value" />
              </FormControl>
              <FormLabel class="font-normal">{{ value }}</FormLabel>
            </FormItem>
          </RadioGroup>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
  </div>

  <FormField v-slot="{ value, handleChange }" name="shared">
    <FormItem>
      <FormControl>
        <div class="flex items-center">
          <Switch
            :checked="value"
            @update:checked="handleChange"
          />
          <Label class="ml-2">Shared</Label>
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField
    v-slot="{ value, handleChange }"
    name="geofence"
    v-if="geojson.geometry.type === 'Polygon'"
  >
    <FormItem>
      <FormControl>
        <div class="flex items-center">
          <Switch
            :checked="value"
            @update:checked="handleChange"
          />
          <Label class="ml-2">Virtual Geofence</Label>
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <div v-if="getValues().geofence" class="p-2 space-y-3 border rounded border-gray-200">
    <FormField
      v-slot="{ componentField }"
      name="geofenceEvents"
    >
      <FormItem>
        <FormControl>
          <RadioGroup v-bind="componentField" class="flex space-x-3">
            <FormItem class="flex items-center space-y-0 gap-x-3">
              <FormControl>
                <RadioGroupItem value="in" />
              </FormControl>
              <FormLabel class="font-normal">In</FormLabel>
            </FormItem>
            <FormItem class="flex items-center space-y-0 gap-x-3">
              <FormControl>
                <RadioGroupItem value="out" />
              </FormControl>
              <FormLabel class="font-normal">Out</FormLabel>
            </FormItem>
            <FormItem class="flex items-center space-y-0 gap-x-3">
              <FormControl>
                <RadioGroupItem value="both" />
              </FormControl>
              <FormLabel class="font-normal">Both</FormLabel>
            </FormItem>
          </RadioGroup>
        </FormControl>
      </FormItem>
      <FormMessage />
    </FormField>

    <FormField
      v-slot="{ value, handleChange }"
      name="geofencePrompt"
      v-if="getValues().geofence && geojson.geometry.type === 'Polygon'"
    >
      <FormItem>
        <FormControl>
          <div class="flex items-center">
            <Switch
              :checked="value"
              @update:checked="handleChange"
            />
            <Label class="ml-2">Prompt user on crossing</Label>
          </div>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
  </div>

  <FormField v-slot="{ componentField }" name="images" v-if="!predefined && feature?.properties.resource">
    <FormItem>
      <FormLabel>Images</FormLabel>
      <FormControl>
        <input
          type="file"
          accept=".jpg, .jpeg, .png, .webp"
          @change="setImagesPreview"
          v-bind="componentField"
          multiple
          class="text-sm"
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <ImagesList v-if="previews.fullsizes.length" :images="previews" />

  <div class="flex items-center justify-between">

    <div class="flex justify-end w-full">
      <!-- Button component doesn't pass ref for some reason, so we need to use a button classes. Need to find a way to fix -->
      <button type="submit" :disabled="isSubmiting" ref="submitButton" class="h-9 px-4 py-2 bg-primary text-white hover:opacity-90 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
        <Spinner v-if="isSubmiting" class="w-4 h-4" />
        <span v-else>
          <span v-if="!editMode">Upload</span>
          <span v-else>Save</span>
        </span>
      </button>
    </div>
  </div>
</Form>
</template>
