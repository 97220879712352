<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

import { MapSource, MapLayer, MapImageMarker } from '@/components/map'
import { DialogCreate, MapProjectLayer, MapDraw, MapRasterLayers } from '@/components/map'

mapboxgl.accessToken = 'pk.eyJ1IjoiZWFydGhwYmMiLCJhIjoiY2x1dGRiMGU4MDdrdjJsb2ducG5pbG41ayJ9.XiX9hVsMSiOXSPf78V3dag'

const renderIncrement = ref(0)

const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const store = useStore()
const mapReady = computed(() => store.getters['geo/getMapReady'])
const projects = computed(() => store.getters['project/getProjects'])
const flatedProjects = computed(() => store.getters['project/getFlatedProjects'])

const projectsToRender = computed(() => {
  if (!projectId.value) {
    return projects.value
  } else {
    const currentProject = flatedProjects.value.find((project) => project.project_id === projectId.value)

    if (!currentProject) return null

    if (currentProject?.children?.length > 0) {
      return [...currentProject.children, currentProject]
    } else {
      return [currentProject]
    }
  }
})

const params = computed(() => store.getters['search/getParams'])

const mapParams = computed(() => {
  const zoom = params.value.find((param) => param.key === 'zoom')
  const centerLat = params.value.find((param) => param.key === 'center_lat')
  const centerLng = params.value.find((param) => param.key === 'center_lng')

  return zoom && centerLat && centerLng ? {
    center: [centerLng.value, centerLat.value],
    zoom: zoom.value
  } : null
})

const localMapOptions = JSON.parse(localStorage.getItem('mapOptions'))

const map = ref(null)

const defaultOptions = {
  center: [-91.874, 42.76],
  zoom: 4
}

const createMap = async () => {
  map.value = new mapboxgl.Map({
    container: 'mapDiv',
    minZoom: 1,
    maxZoom: 17,
    logoPosition: 'bottom-right',
    // merge options from local storage with default
    ...mapParams.value ? mapParams.value : localMapOptions ? localMapOptions : defaultOptions
  })

  map.value.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  map.value.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }), 'bottom-right'
  )

  store.commit('geo/SET_MAP', map.value)
}

const imageCache = new Map()

function loadImageAndCache(code, url) {
  if (imageCache.has(code)) {
    return imageCache.get(code)
  }

  const loadingPromise = new Promise((resolve, reject) => {
    map.value.loadImage(url, (error, image) => {
      if (error) {
        return
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const size = 40
      const borderWidth = 3
      canvas.width = size
      canvas.height = size

      // Draw circular border
      ctx.beginPath()
      ctx.arc(size / 2, size / 2, (size / 2) - (borderWidth / 2), 0, Math.PI * 2)
      ctx.lineWidth = borderWidth
      ctx.strokeStyle = 'white'
      ctx.stroke()

      // Create circular clipping path
      ctx.beginPath()
      ctx.arc(size / 2, size / 2, (size / 2) - (borderWidth / 2), 0, Math.PI * 2)
      ctx.clip()

      ctx.drawImage(image, 0, 0, size, size);

      const resizedImage = ctx.getImageData(0, 0, size, size)

      map.value.addImage(code, resizedImage, { pixelRatio: 0.1 })

      resolve()
    })
  })

  imageCache.set(code, loadingPromise)
  return loadingPromise
}

onMounted(async () => {
  await createMap()

  map.value.on('load', async () => {
    await store.dispatch('geo/mapReady', true)

    map.value.on('styleimagemissing', async (e) => {
      // e.id is url of the missing image
      await loadImageAndCache(e.id, e.id)
    })
  })

  // on setStyle mapbox clear all layers and sources, so we need to re-render everything :(
  // increment render counter when map global style is changed to trigger re-render, maybe found better solution.
  map.value.on('style.load', () => {
    renderIncrement.value++
  })
})

</script>

<template>
  <div class="relative min-h-full w-full h-full bg-slate-100 overflow-hidden">
    <div id="mapDiv" class="map w-full h-full"></div>
    <div v-if="mapReady && map" :key="renderIncrement">
      <MapRasterLayers :map="map" />

      <MapSource
        :map="map"
        type="geojson"
        :id="'empty-source'"
        :data="{
          type: 'FeatureCollection',
          features: []
        }"
      >
        <MapLayer
          :map="map"
          :id="'default-layer'"
          :source="'empty-source'"
          type="fill"
        >
          <MapLayer
            :map="map"
            :id="'project-layer'"
            :source="'empty-source'"
            type="fill"
            :before-id="'default-layer'"
          >
            <div v-if="projectsToRender && projectsToRender.length" :key="projectsToRender.length">
              <MapProjectLayer
                v-for="project in projectsToRender"
                :map="map"
                :key="project"
                :project="project">
              </MapProjectLayer>
            </div>
          </MapLayer>
        </MapLayer>
      </MapSource>

      <MapDraw :map="map">
        <div class="absolute z-50 bottom-16 md:bottom-12 mb-3 md:mb-1 right-10 py-2 px-3" :class="{'mt-9': !!projectId}">
          <div class="flex items-end justify-end">
            <DialogCreate />
          </div>
        </div>
      </MapDraw>
    </div>
  </div>
</template>

<style>
.slideRight-enter-active, .slideRight-leave-active {
  transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.slideRight-enter-from, .slideRight-leave-to {
  transform: translateX(-3%);
  opacity: 0;
}
</style>
