<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { WS } from '@/services/ws'

const PROJECT_FEATURES_TOPIC = {
  commonName: 'projects.features',
  name(id) {
    return `${this.commonName}(${id})`
  }
}

const route = useRoute()
const store = useStore()

const cleanUpTimer = ref(null)
const ws = ref(null)
const currentConnectedProjectId = ref(null)

const currentProjectId = computed(() => {
  if (!route.params.project_id) return null
  return parseInt(route.params.project_id)
})

function getProtocol() {
  return location.protocol === 'https:' ? 'wss' : 'ws'
}

function connectWS() {
  let url = `${getProtocol()}://${window.location.host}/api/3/ws`
  if (url.includes('localhost')) {
    url = url.replace(/:\d+/, ':8000')
  }
  ws.value = WS(url, onMsg, onOpen, onClose)
}

function onMsg(topic, event, data) {
  if (topic) {
    const truncatedTopic = truncTopic(topic)
    const handler = topicToHandlerMap[truncatedTopic]
    if (handler) handler(topic, event, data)
  }
}

function onClose() {
  clearInterval(cleanUpTimer.value)
  cleanUpTimer.value = null

  console.log('WS closed')
  setTimeout(() => {
    console.log('WS reconnecting')
    connectWS()
  }, 100)
}

function onOpen() {
  console.log('open')
  cleanUpTimer.value = setInterval(() => {
    store.commit('geo/CLEANUP_BEACONS')
  }, 1000 * 60 * 2)
}

function sub(topics) {
  topics.forEach(topic => {
    ws.value.subscribe(topic)
  })
}

function unsub(topics) {
  topics.forEach(topic => {
    ws.value.unsubscribe(topic)
  })
}

function truncTopic(topic) {
  return topic.split('(')[0]
}

function convertBeacon(beacon) {
  const coords = [beacon.lone6, beacon.late6]
  let rel_ts = null
  if (beacon.rel_ts.toString().length === 10) {
    rel_ts = beacon.rel_ts * 1000
  } else if (beacon.rel_ts.toString().length === 13) {
    rel_ts = beacon.rel_ts
  }

  const props = {
    id: beacon.id,
    member_id: beacon.member_id,
    type: beacon.type,
    project_id: beacon.project_id,
    member_avatar_url: beacon.member_avatar_url,
    member_display_name: beacon.member_display_name,
    rel_ts: rel_ts,
  }
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coords
    },
    properties: props
  }
}

async function handleProjectEvent(topic, event, data) {
  switch (event) {
    case 'task':
      store.commit('planet/UPDATE_ORDER_STATUS', data)
      break
    case 'beacon.next':
      const geoJson = convertBeacon(data.beacon)
      store.commit('geo/NEXT_BEACON', geoJson)
      break
    case 'beacon.close':
      const memberId = data.beacon.member_id
      store.commit('geo/REMOVE_BEACON', memberId)
      break
    default:
      break
  }
}

const topicToHandlerMap = {
  [PROJECT_FEATURES_TOPIC.commonName]: handleProjectEvent
}


const connectToProject = (projectId) => {
  sub([PROJECT_FEATURES_TOPIC.name(projectId)])
  currentConnectedProjectId.value = projectId
}

const disconnectFromProject = (projectId) => {
  unsub([PROJECT_FEATURES_TOPIC.name(projectId)])
  currentConnectedProjectId.value = null
}

watch(
  () => currentProjectId.value,
  (newVal, oldVal) => {
    if (newVal !== currentConnectedProjectId.value) {
      if (oldVal) {
        disconnectFromProject(oldVal)
      }
      if (newVal) {
        connectToProject(newVal)
      }
    }
  }
)

onMounted(() => {
  connectWS()

  if (currentProjectId.value) {
    connectToProject(currentProjectId.value)
  }
})

onBeforeUnmount(() => {
  disconnectFromProject(currentConnectedProjectId.value)
})
</script>

<template>
  <!-- This component doesn't render anything, it just handles WS communication -->
</template>
