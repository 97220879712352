import { HOST, API } from '@/utils/http'

export const fetchLayers = async ({commit}) => {
  const response = await API({
    method: 'GET',
    url: `${HOST}/layers`
  })

  const layers = response.data.layers.map(layer => {
    return {
      uuid: layer.uuid,
      type: 'Feature',
      geometry: JSON.parse(layer.geometry),
      properties: {
        type: 1,
        visible: false,
        code: layer.uuid,
        name: layer.name,
        color: '#FF0000',
        meta: layer
      }
    }
  })

  commit('SET_LAYERS', layers)
}
