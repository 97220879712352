<script setup>
import { onBeforeMount, onMounted, ref, watch, onUnmounted } from 'vue'
import axios from 'axios'
import Chart from 'chart.js/auto'
import { sendEmailVerification } from 'firebase/auth';

const chart = ref(null)
const chartData = ref(null)
const currentHour = ref(null)
const currentWeather = ref(null)
const weatherChartCtx = ref(null)

const codeToWeather = {
    0: { emoji: "☀️", label: "Clear sky" },
    1: { emoji: "🌤️", label: "Mainly clear" },
    2: { emoji: "🌤️", label: "Partly cloudy" },
    3: { emoji: "☁️", label: "Overcast" },
    45: { emoji: "🌫️", label: "Fog" },
    48: { emoji: "🌫️", label: "Depositing rime fog" },
    51: { emoji: "🌧️", label: "Drizzle: Light intensity" },
    53: { emoji: "🌧️", label: "Drizzle: Moderate intensity" },
    55: { emoji: "🌧️", label: "Drizzle: Dense intensity" },
    56: { emoji: "❄️", label: "Freezing Drizzle: Light intensity" },
    57: { emoji: "❄️", label: "Freezing Drizzle: Dense intensity" },
    61: { emoji: "🌧️", label: "Rain: Slight intensity" },
    63: { emoji: "🌧️", label: "Rain: Moderate intensity" },
    65: { emoji: "🌧️", label: "Rain: Heavy intensity" },
    66: { emoji: "❄️", label: "Freezing Rain: Light intensity" },
    67: { emoji: "❄️", label: "Freezing Rain: Heavy intensity" },
    71: { emoji: "🌨️", label: "Snow fall: Slight intensity" },
    73: { emoji: "🌨️", label: "Snow fall: Moderate intensity" },
    75: { emoji: "🌨️", label: "Snow fall: Heavy intensity" },
    77: { emoji: "❄️", label: "Snow grains" },
    80: { emoji: "🌦️", label: "Rain showers: Slight intensity" },
    81: { emoji: "🌦️", label: "Rain showers: Moderate intensity" },
    82: { emoji: "🌦️", label: "Rain showers: Violent intensity" },
    85: { emoji: "🌨️", label: "Snow showers: Slight intensity" },
    86: { emoji: "🌨️", label: "Snow showers: Heavy intensity" },
    95: { emoji: "⛈️", label: "Thunderstorm: Slight or moderate" },
    96: { emoji: "⛈️", label: "Thunderstorm with slight hail" },
    99: { emoji: "⛈️", label: "Thunderstorm with heavy hail" }
}

const props = defineProps({
  geometry: {
    type: Object,
    required: true
  }
})

let latitude = null
let longitude = null

if (props.geometry.type == 'Point') {
  latitude = props.geometry.coordinates[1]
  longitude = props.geometry.coordinates[0]
} else if (props.geometry.type == 'Polygon') {
  const coordinates = props.geometry.coordinates[0]
  latitude = coordinates[0][1]
  longitude = coordinates[0][0]
} else if (props.geometry.type == 'LineString') {
  const coordinates = props.geometry.coordinates
  latitude = coordinates[0][1]
  longitude = coordinates[0][0]
}

const fetchWeather = async () => {
  const url = `https://api.open-meteo.com/v1/forecast`
  try {
    const { data } = await axios.get(url, {
      params: {
        latitude: latitude,
        longitude: longitude,
        hourly: 'temperature_2m,weather_code',
        forecast_days: 2
      }
    })

    const currentDate = new Date()
    currentHour.value = new Date().getHours()
    currentWeather.value = codeToWeather[data.hourly.weather_code[currentHour.value]]

    const filteredData = data.hourly.time.reduce((acc, timestamp, index) => {
      const date = new Date(timestamp)

      if (date >= currentDate) {
        acc.time.push(`${date.getHours()}:00`)
        acc.temperature_2m.push(data.hourly.temperature_2m[index])
      }
      return acc
    }, { time: [], temperature_2m: [] })

    chartData.value = {
      labels: filteredData.time.slice(0, 18),
      datasets: [
        {
          label: 'Temperature (°C)',
          data: filteredData.temperature_2m.slice(0, 18),
          borderColor: '#fbbf24',
          backgroundColor: '#fbbf24',
        }
      ]
    }
  } catch (e) {
    toast({ title: `Can't get weather. ${e}`, })
  }
}

onBeforeMount(async () => {
  await fetchWeather()
})

onUnmounted(() => {
  if (chart.value) {
    chart.value.destroy()
  }
})

watch(() => chartData.value, (value) => {
  if (value) {
    if (chart.value) {
      return
    }
    chart.value = new Chart(weatherChartCtx.value, {
      type: 'line',
      data: value,
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
          },
          legend: {
            position: 'bottom',
            display: false
          }
        }
      }
    })
  }
})
</script>

<template>
  <div class="bg-white rounded-lg shadow mb-3 px-3 py-3">
    <div v-if="chartData">
      {{ currentWeather.emoji }} {{ currentWeather.label }}
    </div>
    <canvas v-show="chartData" ref="weatherChartCtx" width="100%" height="50px"></canvas>
  </div>
</template>
