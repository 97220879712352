<script setup>
import {computed, onBeforeMount, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import { useRoute } from 'vue-router'

import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import {Button} from '@/components/ui/button'
import {Layers2} from 'lucide-vue-next'

const route = useRoute()

const store = useStore()

const map = computed(() => store.getters['geo/getMap'])
const layers = computed(() => store.getters['geo/getLayers'])

const isShow = ref(false)

const orgs = computed(() => store.getters['org/getOrgs'])
const projects = computed(() => store.getters['project/getProjects'])
const selectedProject = computed(() => store.getters['project/current'])
const projectsByOrgs = computed(() => {
  const sortedProjects = orgs.value.map(org => {
    const orgProjects = projects.value.filter(i => i.organization_id === org.organization_id)
    return { org, orgProjects }
  }).filter(item => item.orgProjects.length > 0)
  return sortedProjects
})

const currentOrg = computed(() => {
  if (projectsByOrgs.value.length === 1) {
    return projectsByOrgs.value[0].org
  } else if (projectsByOrgs.value.length > 1 && selectedProject.value) {
    return orgs.value.find(org => org.organization_id === selectedProject.value.organization_id)
  } else if (projectsByOrgs.value.length > 1 && route.params?.org_id) {
    return orgs.value.find(org => org.organization_id === parseInt(route.params.org_id))
  } else {
    return null
  }
})

const baseLayers = ref([
  {
    status: true,
    name: 'Standard',
    styleID: 'mapbox://styles/mapbox/standard'
  },
  {
    status: false,
    name: 'Satellite',
    styleID: 'mapbox://styles/mapbox/standard-satellite'
  },
  {
    status: false,
    name: 'OpenStreetMap',
    source: 'osm-tiles-source',
    id: 'osm-tiles-layer'
  },
  {
    status: false,
    name: 'OpenTopoMap',
    source: 'osmtopo-tiles-source',
    id: 'osmtopo-tiles-layer'
  }
])

const handleChange = async (layer) => {
  if (layer.styleID) {
    map.value.setStyle(layer.styleID)
    baseLayers.value.forEach((item) => {
      if (item.styleID !== layer.styleID) {
        item.status = false
      }
    })
  } else if (layer.source) {
    map.value.setLayoutProperty(layer.id, 'visibility', layer.status ? 'visible' : 'none')
  }
}

</script>

<template>
  <div>
    <div v-if="isShow" class="bg-white p-3 mb-2 rounded-md shadow-lg z-50 inline-flex flex-col w-full">
      <div class="inline-flex flex-col space-y-1">
        <div v-for="layer of baseLayers" class="flex p-0.5 items-center bg-white rounded-full max-w-fit pointer-events-auto">
          <Switch :id="'layer-' + layer.name" v-model:checked="layer.status" @update:checked="handleChange(layer)" />
          <Label :for="'layer-' + layer.name" class="px-1.5">{{layer.name}}</Label>
        </div>
      </div>
    </div>

    <Button variant="white" class="rounded-full px-3 py-2 pointer-events-auto shadow-lg" @click="isShow = !isShow">
      <Layers2 class="mr-2" />
      <span class="font-semibold">Background</span>
    </Button>
  </div>
</template>
