<script setup>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { LayerList, NewLayerDialog } from '@/components/layers'

const store = useStore()
const layers = computed(() => store.getters['layers/getLayers'])
const mapReady = computed(() => store.getters['geo/getMapReady'])
const orgs = computed(() => store.getters['org/getOrgs'])

const orgLayers = computed(() => {
  return orgs.value.map((org) => {
    return {
      ...org,
      layers: layers.value.filter((layer) => layer.properties.organization_id === org.organization_id)
    }
  })
})

onMounted(async () => {
  await store.dispatch('layers/fetchLayers')
})

</script>

<template>
  <div v-for="org in orgLayers" :key="org.organization_id + org.layers?.length">
    <div v-if="org.layers?.length">
      <div class="mb-1 flex items-center justify-between">
        <h3 class="px-2 font-bold">{{ org.organization_name }}</h3>
        <div>
          <NewLayerDialog :feature="{}" :orgs="orgs" :org="org" />
        </div>
      </div>
      <div v-if="mapReady">
        <LayerList :layers="layers" :key="layers.length" />
      </div>
    </div>
  </div>
</template>
